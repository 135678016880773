@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Raleway:wght@400;700&display=swap');

@tailwind base;

@layer base {
  body {
    @apply min-h-screen;
  }

  .theme-color {
    @apply bg-rmf text-white;
    @apply sm:bg-gradient-to-r from-rmf via-rmf to-gray-50 sm:opacity-100;
    @apply md:text-white;
    @apply lg:text-black;
  }

  .nav-color {
    @apply bg-white;
    @apply dark:bg-rmf;
  }

  .wrapper {
    @apply space-y-6 my-5 p-5 sm:pt-10 md:pt-32 lg:pt-10;
  }

  .title {
    @apply font-cursive font-bold text-center tracking-normal text-5xl mb-5;
    @apply sm:mb-8;
    @apply md:text-6xl md:mb-10 lg:mb-12;
  }

  .subtitle {
    @apply font-sans font-bold tracking-wider text-lg;
  }

  .data {
    @apply ml-4 mb-2;
  }

  .paragraph {
    @apply text-base leading-relaxed;
    @apply md:text-lg md:leading-loose;
  }

  .link {
    @apply py-2 px-2 inline-block rounded-2xl uppercase text-xs tracking-wide transition-colors duration-700;
    @apply sm:text-sm;
    @apply md:px-5 md:text-base md:tracking-wider;
  }

  .button {
    @apply w-full bg-rmfAccent-50 text-black text-sm font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider;
    @apply hover:bg-rmfAccent-100 focus:outline-none;
  }

  /* Header */
  .header-link {
    @apply my-1 px-3 font-normal text-rmf;
    @apply hover:bg-gray-200 hover:text-rmf;
    @apply dark:text-white;
    @apply dark:hover:bg-blue-100 dark:hover:text-rmf;
  }

  /* Footer */
  .footer-text {
    @apply text-base text-white sm:text-gray-900;
    @apply md:text-white;
  }

  .footer-link {
    @apply hover:underline hover:text-rmfAccent-50;
  }

  .social-link {
    @apply text-3xl text-white;
    @apply md:text-gray-800;
    @apply hover:text-rmfAccent-50;
  }

  .copy {
    @apply text-white;
    @apply md:text-gray-700;
  }

  /* Services */
  .service-link {
    @apply w-full text-center font-bold border border-white bg-rmf text-gray-100;
    @apply md:flex-1 md:text-left;
    @apply hover:border-gray-500 hover:bg-white hover:text-rmf;
    @apply dark:border-blue-100 dark:bg-blue-100 dark:text-rmf;
    @apply dark:hover:bg-blue-200 dark:border-blue-200;
  }

  .inner-link {
    @apply w-full inline-block sm:text-xs;
  }

  /* Contact form */
  .text-input,
  .form-textarea {
    @apply border border-rmf rounded-full px-5 py-2 w-full appearance-none placeholder-gray-500 outline-none;
    @apply dark:bg-blue-100 dark:text-rmf dark:border-white dark:placeholder-rmf;
  }

  .error {
    @apply text-red-500 text-xs;
  }
}

@tailwind components;
@tailwind utilities;
